import React, { useContext, useRef } from 'react';
import { noop, stubTrue, stubFalse } from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import PrintIcon from '@material-ui/icons/Print';
import {
  DomainObject,
  DetailToolbarProps,
  DetailHandle,
  DetailContext,
  DialogHandle,
  useEventCallback,
  DetailMode,
  DetailToolbarButtonType,
  ConfirmDialog,
  ShareDialog,
  EmptyComponent,
} from '@eas/common-web';
import { useStyles } from './detail-styles';
import { DetailToolbarButton } from './detail-toolbar-button';
import {
  MapEvidenceContext,
  MapEvidenceView,
} from '../map-evidence/map-context';

export function DetailToolbar<OBJECT extends DomainObject>({
  title,
  subtitle,
  Before = EmptyComponent,
  After = EmptyComponent,
  ActionBar,
  showBtn = stubTrue,
  disableBtn = stubFalse,
  handleSave,
  handleDelete: handleDeleteExternal,
}: DetailToolbarProps<OBJECT>) {
  /**
   * Styles.
   */
  const classes = useStyles();

  /**
   * Context stuff.
   */
  const mapEvidenceContext = useContext(MapEvidenceContext);
  const { mode, source } = useContext<DetailHandle<OBJECT>>(DetailContext);
  const {
    refresh,
    startNew,
    startEditing,
    cancelEditing,
    validate,
    del,
    save,
    openExportDialog,
    reportTag,
  } = useContext(DetailContext);

  /**
   * Confirm deletion dialog / Share dialog.
   */
  const confirmDeleteDialog = useRef<DialogHandle>(null);
  const shareDeleteDialog = useRef<DialogHandle>(null);

  const handleDelete = useEventCallback(() => {
    confirmDeleteDialog.current?.open();
  });

  const handleShare = useEventCallback(() => {
    shareDeleteDialog.current?.open();
  });

  const handleNew = useEventCallback(() => {
    startNew();
  });

  const titleText =
    typeof title === 'string'
      ? title
      : typeof title === 'function'
      ? title(source.data)
      : '';

  const subTitleText =
    typeof subtitle === 'string'
      ? subtitle
      : typeof subtitle === 'function'
      ? subtitle(source.data)
      : '';

  const isModeNew = mode === DetailMode.NEW;
  const isModeEdit = mode === DetailMode.EDIT;
  const isModeNone = mode === DetailMode.NONE;
  const isModeView = mode === DetailMode.VIEW;

  const isViewMap = mapEvidenceContext?.view === MapEvidenceView.MAP;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showNew = showBtn?.('NEW');
  // const showNew = true;
  const disabledNew = disableBtn('NEW') || isViewMap || isModeNew || isModeEdit;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showRefresh = showBtn?.('REFRESH') && !isModeNone;
  // const showRefresh = true;
  const disabledRefresh = disableBtn('REFRESH') || isViewMap || !isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showShare = showBtn?.('SHARE') && !isModeNone;
  // const showShare = true;
  const disabledShare = disableBtn('SHARE') || !isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showEdit = showBtn?.('EDIT') && !isModeNone;
  // const showEdit = true;
  const disabledEdit =
    disableBtn('EDIT') ||
    isViewMap ||
    !isModeView ||
    (source.data as any)?.active === false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showCheck = showBtn?.('CHECK') && !isModeNone;
  // const showCheck = true;
  const disabledCheck =
    disableBtn('CHECK') || isViewMap || isModeNone || isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showSave = showBtn?.('SAVE') && !isModeNone;
  // const showSave = true;
  const disabledSave =
    disableBtn('SAVE') || isViewMap || isModeNone || isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showClose = showBtn?.('CLOSE') && !isModeNone;
  // const showClose = true;
  const disabledClose =
    disableBtn('CLOSE') || isViewMap || isModeNone || isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showRemove = showBtn?.('REMOVE') && !isModeNone;
  // const showRemove = true;
  const disabledRemove = disableBtn('REMOVE') || isViewMap || !isModeView;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const showExport = showBtn?.('EXPORT') && !isModeNone && reportTag;
  // const showExport = true;
  const disabledExport = disableBtn?.('EXPORT') || !isModeView;

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h1" className={classes.title}>
          {titleText} <span className={classes.subTitle}>{subTitleText}</span>
        </Typography>
        <div className={classes.toolbarWrapper}>
          {Before && <Before />}
          {showNew && (
            <DetailToolbarButton
              label="Nový"
              startIcon={<AddBoxOutlinedIcon />}
              disabled={disabledNew}
              onClick={handleNew}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showRefresh && (
            <DetailToolbarButton
              startIcon={<RefreshIcon />}
              label="Obnovit"
              disabled={disabledRefresh}
              onClick={refresh}
            />
          )}
          {showShare && (
            <DetailToolbarButton
              startIcon={<ShareIcon />}
              label="Sdílet"
              disabled={disabledShare}
              onClick={handleShare}
            />
          )}
          {showEdit && (
            <DetailToolbarButton
              startIcon={<EditOutlinedIcon />}
              label="Upravit"
              disabled={disabledEdit}
              onClick={startEditing}
            />
          )}
          {showCheck && (
            <DetailToolbarButton
              startIcon={<PlaylistAddCheckIcon />}
              label="Kontrola"
              disabled={disabledCheck}
              onClick={() => validate()}
            />
          )}
          {showSave && (
            <DetailToolbarButton
              startIcon={<SaveIcon />}
              label="Uložit"
              onClick={handleSave === undefined ? save : handleSave}
              disabled={disabledSave}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {showClose && (
            <DetailToolbarButton
              startIcon={<UndoIcon />}
              label="Zrušit"
              disabled={disabledClose}
              onClick={cancelEditing}
            />
          )}

          {showRemove && (
            <DetailToolbarButton
              startIcon={<DeleteOutlinedIcon />}
              label="Smazat"
              disabled={disabledRemove}
              onClick={
                handleDeleteExternal === undefined
                  ? handleDelete
                  : handleDeleteExternal
              }
              type={DetailToolbarButtonType.SECONDARY}
            />
          )}

          {showExport && (
            <DetailToolbarButton
              startIcon={<PrintIcon />}
              label="Exportovat"
              disabled={disabledExport}
              onClick={openExportDialog}
            />
          )}

          {After && <After />}
        </div>
        {ActionBar && (
          <div className={classes.actionbarWrapper}>
            <ActionBar />
          </div>
        )}
      </div>
      <ConfirmDialog
        ref={confirmDeleteDialog}
        onConfirm={del}
        onCancel={noop}
        title="Varování"
        text="Opravdu chcete smazat záznam?"
      />
      <ShareDialog ref={shareDeleteDialog} onCancel={noop} />
    </>
  );
}
